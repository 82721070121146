import React from "react";
import { cn } from "@/utils/utils";

const NewsIcon = ({ className }: { className?: string }) => {
	return (
		<span className={cn("block w-5 h-5 text-gray-500 ", className)}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={"100%"}
				height={"100%"}
				viewBox="0 0 21 18"
				fill="none">
				<path
					d="M1.82275 15.6718C2.14777 15.9983 2.58834 16.1819 3.04778 16.1824C3.50723 16.1819 3.9478 15.9983 4.27283 15.6718C4.59789 15.3452 4.78081 14.9024 4.7813 14.4403V4.51559L2.07841 4.51661C1.87592 4.51701 1.68175 4.59803 1.53848 4.74207C1.39522 4.88611 1.31455 5.08142 1.31429 5.28523C1.31429 5.28526 1.31429 5.2853 1.31429 5.28533L1.21429 5.28521L1.82275 15.6718ZM1.82275 15.6718C1.49769 15.3452 1.31477 14.9024 1.31429 14.4403L1.82275 15.6718ZM18.9836 15.4775C19.4324 15.0267 19.6849 14.4155 19.6857 13.7777V2.58599C19.6854 2.38208 19.6046 2.18671 19.4612 2.0427C19.3179 1.89872 19.1236 1.81782 18.9211 1.8176C18.921 1.8176 18.921 1.8176 18.921 1.8176L6.45986 1.8176M18.9836 15.4775L6.45981 1.7176M18.9836 15.4775C18.5349 15.9283 17.9266 16.1817 17.2922 16.1824H5.04735C5.22847 15.9732 5.37614 15.7365 5.4846 15.4805C5.62397 15.1516 5.69572 14.7979 5.69555 14.4404C5.69555 14.4404 5.69555 14.4404 5.69555 14.4404V2.58599M18.9836 15.4775L5.59555 2.58586M6.45986 1.8176H6.45981V1.7176M6.45986 1.8176C6.45988 1.8176 6.45991 1.8176 6.45994 1.8176L6.45981 1.7176M6.45986 1.8176C6.25739 1.8179 6.0632 1.89882 5.9199 2.04279C5.77655 2.1868 5.69582 2.38214 5.69555 2.58599M6.45981 1.7176C6.23069 1.71791 6.01104 1.80948 5.84902 1.97225C5.68701 2.13501 5.59586 2.35568 5.59555 2.58586M5.69555 2.58599V2.58586H5.59555M5.69555 2.58599L5.59555 2.58586M18.9212 0.9H18.9211L6.45982 0.9L6.45971 0.9C6.01456 0.900462 5.58786 1.07833 5.27322 1.39442C4.95861 1.71049 4.78174 2.13895 4.78128 2.58574V2.58584V3.598L2.07782 3.59902L2.07767 3.59902C1.63264 3.59984 1.20618 3.77789 0.891733 4.094C0.577305 4.41008 0.400528 4.83842 0.4 5.28509V5.28521L0.4 14.4404L0.4 14.4405C0.400792 15.1455 0.679886 15.8214 1.1762 16.32C1.67252 16.8186 2.34556 17.0992 3.04767 17.1H3.04778H17.2923H17.2924C18.1695 17.0991 19.0102 16.7487 19.6302 16.1258C20.2503 15.503 20.599 14.6586 20.6 13.778V13.7779V2.58584V2.58572C20.5995 2.13889 20.4225 1.71043 20.1078 1.39437C19.7931 1.07829 19.3664 0.900446 18.9212 0.9Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.2"
				/>
				<path
					d="M7.74921 3.85423C7.70095 3.90272 7.67373 3.96856 7.67366 4.03732C7.67366 4.03734 7.67366 4.03737 7.67366 4.03739M7.74921 3.85423L7.57366 4.03732L7.67366 4.03739M7.74921 3.85423C7.79746 3.80576 7.86278 3.77859 7.9308 3.77853M7.74921 3.85423L7.9308 3.77853M7.67366 4.03739V6.32737M7.67366 4.03739V6.32737M7.67366 6.32737L7.67366 6.32744M7.67366 6.32737L7.67366 6.32744M7.67366 6.32744C7.67373 6.3962 7.70095 6.46204 7.74921 6.51053C7.79747 6.55901 7.86282 6.58619 7.93087 6.58623H10.2103C10.2784 6.58619 10.3437 6.55901 10.392 6.51053C10.4403 6.46202 10.4675 6.39616 10.4675 6.32737V4.03739C10.4675 3.9686 10.4403 3.90273 10.392 3.85423C10.3437 3.80576 10.2784 3.77859 10.2104 3.77853C10.2104 3.77853 10.2103 3.77853 10.2103 3.77853M7.67366 6.32744L10.2104 3.67853L10.2103 3.77853M10.2103 3.77853H7.93087M10.2103 3.77853H7.93087M7.93087 3.77853L7.9308 3.77853M7.93087 3.77853L7.9308 3.77853M7.93069 7.50382H7.9308H10.2104H10.2105C10.5212 7.50348 10.819 7.37933 11.0385 7.15876C11.2581 6.9382 11.3815 6.63926 11.3818 6.32755V6.32744V4.03732V4.03721C11.3815 3.7255 11.2581 3.42656 11.0385 3.206C10.819 2.98543 10.5212 2.86128 10.2105 2.86094H10.2104L7.9308 2.86094L7.93069 2.86094C7.62 2.86128 7.32221 2.98543 7.10266 3.206C6.88312 3.42656 6.75971 3.7255 6.75937 4.03721V4.03732L6.75937 6.32744L6.75938 6.32755C6.75971 6.63926 6.88312 6.9382 7.10266 7.15876C7.32221 7.37933 7.62 7.50348 7.93069 7.50382Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.2"
				/>
				<path
					d="M18.1065 3.80625H13.1501C13.0287 3.80625 12.9124 3.8547 12.8267 3.94079C12.741 4.02687 12.693 4.14351 12.693 4.26504C12.693 4.38658 12.741 4.50322 12.8267 4.5893C12.9124 4.67539 13.0287 4.72384 13.1501 4.72384H18.1065C18.2279 4.72384 18.3442 4.67539 18.4299 4.5893C18.5156 4.50322 18.5637 4.38658 18.5637 4.26504C18.5637 4.14351 18.5156 4.02687 18.4299 3.94079C18.3442 3.8547 18.2279 3.80625 18.1065 3.80625Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.2"
				/>
				<path
					d="M18.1065 6.58652H13.1501C13.0287 6.58652 12.9124 6.63497 12.8267 6.72106C12.741 6.80714 12.693 6.92379 12.693 7.04532C12.693 7.16685 12.741 7.2835 12.8267 7.36957C12.9124 7.45566 13.0287 7.50411 13.1501 7.50411H18.1065C18.2279 7.50411 18.3442 7.45566 18.4299 7.36957C18.5156 7.2835 18.5637 7.16685 18.5637 7.04532C18.5637 6.92379 18.5156 6.80714 18.4299 6.72106C18.3442 6.63497 18.2279 6.58652 18.1065 6.58652Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.2"
				/>
				<path
					d="M18.1058 9.36777H7.40792C7.28653 9.36777 7.17021 9.41622 7.08451 9.50231C6.99883 9.58839 6.95078 9.70504 6.95078 9.82657C6.95078 9.9481 6.99883 10.0647 7.08451 10.1508C7.17021 10.2369 7.28653 10.2854 7.40792 10.2854H18.1058C18.2272 10.2854 18.3435 10.2369 18.4292 10.1508C18.5149 10.0647 18.563 9.9481 18.563 9.82657C18.563 9.70504 18.5149 9.58839 18.4292 9.50231C18.3435 9.41622 18.2272 9.36777 18.1058 9.36777Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.2"
				/>
				<path
					d="M18.1058 12.149H7.40792C7.28653 12.149 7.17021 12.1975 7.08451 12.2836C6.99883 12.3696 6.95078 12.4863 6.95078 12.6078C6.95078 12.7293 6.99883 12.846 7.08451 12.9321C7.17021 13.0182 7.28653 13.0666 7.40792 13.0666H18.1058C18.2272 13.0666 18.3435 13.0182 18.4292 12.9321C18.5149 12.846 18.563 12.7293 18.563 12.6078C18.563 12.4863 18.5149 12.3696 18.4292 12.2836C18.3435 12.1975 18.2272 12.149 18.1058 12.149Z"
					fill="currentColor"
					stroke="currentColor"
					strokeWidth="0.2"
				/>
			</svg>
		</span>
	);
};

export default NewsIcon;
