import { cn } from "@/utils/utils";
import React from "react";

const TiktokIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-12 h-12", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
          fill="black"
        />
        <path
          d="M21.7476 21.4506V20.5163C21.4275 20.4735 21.102 20.4468 20.766 20.4468C16.7595 20.4415 13.5 23.7038 13.5 27.7189C13.5 30.1803 14.727 32.3587 16.6048 33.6775C15.3938 32.3801 14.6523 30.6341 14.6523 28.7227C14.6523 24.7663 17.8212 21.5413 21.7476 21.4506Z"
          fill="#25F4EE"
        />
        <path
          d="M21.9241 32.0436C23.7112 32.0436 25.1729 30.618 25.237 28.8454L25.2423 13.0038H28.1338C28.075 12.6781 28.0431 12.3417 28.0431 12H24.0953L24.09 27.8416C24.026 29.6143 22.5642 31.0398 20.7771 31.0398C20.2223 31.0398 19.6995 30.901 19.2354 30.6554C19.8328 31.4936 20.8144 32.0436 21.9241 32.0436Z"
          fill="#25F4EE"
        />
        <path
          d="M33.5377 18.3803V17.4994C32.4335 17.4994 31.4091 17.1737 30.5449 16.6077C31.3131 17.4887 32.3534 18.1294 33.5377 18.3803Z"
          fill="#25F4EE"
        />
        <path
          d="M30.5446 16.6079C29.7017 15.6415 29.1949 14.3814 29.1949 13.0039H28.1387C28.4107 14.5096 29.307 15.8017 30.5446 16.6079Z"
          fill="#FE2C55"
        />
        <path
          d="M20.7713 24.3924C18.9415 24.3924 17.4531 25.882 17.4531 27.7134C17.4531 28.9895 18.1786 30.1 19.2349 30.6553C18.8402 30.1107 18.6054 29.4433 18.6054 28.7172C18.6054 26.8858 20.0938 25.3961 21.9237 25.3961C22.2651 25.3961 22.5958 25.4549 22.9053 25.551V21.5146C22.5851 21.4718 22.2598 21.4451 21.9237 21.4451C21.865 21.4451 21.8116 21.4504 21.7529 21.4504V24.5472C21.4382 24.4511 21.1128 24.3924 20.7713 24.3924Z"
          fill="#FE2C55"
        />
        <path
          d="M33.5371 18.3804V21.4504C31.4885 21.4504 29.5893 20.7937 28.0422 19.6831V27.7188C28.0422 31.7285 24.7827 34.9962 20.7709 34.9962C19.2239 34.9962 17.7835 34.505 16.6045 33.6774C17.9328 35.1029 19.8267 36 21.9233 36C25.9297 36 29.1946 32.7377 29.1946 28.7225V20.6869C30.7416 21.7975 32.6408 22.4542 34.6894 22.4542V18.5032C34.2892 18.5032 33.9051 18.4605 33.5371 18.3804Z"
          fill="#FE2C55"
        />
        <path
          d="M28.0426 27.7189V19.6833C29.5897 20.7939 31.4889 21.4506 33.5375 21.4506V18.3805C32.3531 18.1296 31.3128 17.4889 30.5446 16.6079C29.3069 15.8017 28.4161 14.5096 28.1333 13.0039H25.2419L25.2365 28.8455C25.1725 30.6181 23.7107 32.0437 21.9237 32.0437C20.814 32.0437 19.8377 31.4938 19.2296 30.6608C18.1733 30.1002 17.4478 28.995 17.4478 27.7189C17.4478 25.8876 18.9362 24.3979 20.766 24.3979C21.1074 24.3979 21.4382 24.4566 21.7476 24.5527V21.4559C17.8212 21.5414 14.6523 24.7663 14.6523 28.7227C14.6523 30.6341 15.3939 32.3748 16.6049 33.6775C17.7838 34.5051 19.2242 34.9963 20.7713 34.9963C24.7778 34.9963 28.0426 31.7287 28.0426 27.7189Z"
          fill="white"
        />
      </svg>
      ;
    </span>
  );
};

export default TiktokIcon;
