import { cn } from "@/utils/utils";
import React from "react";

const NamperfumeFilledIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-[18px] h-[18px] text-red-500", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M14.6781 17.5H3.32194C1.76355 17.5 0.5 16.2364 0.5 14.6781V3.32194C0.5 1.76355 1.76355 0.5 3.32194 0.5H14.6781C16.2364 0.5 17.5 1.76355 17.5 3.32194V14.6781C17.5 16.2364 16.2364 17.5 14.6781 17.5Z"
          fill="currentColor"
          stroke="currentColor"
        />
        <path
          d="M6.58077 7.58906V13.7365H5.77141C5.7397 13.7365 5.71596 13.735 5.69873 13.7331C5.68848 13.6882 5.67969 13.6146 5.67969 13.4991V4.42719H6.44767C6.50496 4.42719 6.54741 4.42968 6.57828 4.43302C6.58076 4.45935 6.58257 4.49445 6.58257 4.5405V5.60345V6.10345H7.08257H7.14372H7.40046L7.55006 5.8948C8.37097 4.74988 9.26008 4.26172 10.1293 4.26172C10.8778 4.26172 11.397 4.49335 11.7406 4.90189C12.0966 5.32516 12.329 6.0149 12.329 7.05488V13.7347H11.5196C11.4869 13.7347 11.4624 13.7331 11.4448 13.7312C11.4347 13.6865 11.4261 13.6135 11.4261 13.4991V7.50632C11.4261 6.75554 11.2931 6.13303 10.9659 5.68856C10.6168 5.21434 10.108 5.02251 9.55559 5.02251C9.05912 5.02251 8.5367 5.27028 8.05999 5.65125C7.57275 6.04063 7.08533 6.60613 6.65147 7.33272L6.58077 7.45114V7.58906Z"
          fill="white"
          stroke="white"
        />
      </svg>
    </span>
  );
};

export default NamperfumeFilledIcon;
