import { cn } from "@/utils/utils";
import React from "react";

function LocationIcon({ className }: { className?: string }) {
  return (
    <span className={cn("block w-6 h-6 text-gray-500 ", className)}>
      <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1501 19.9L13.3815 20.3432L13.1501 19.9ZM12.3499 19.9L12.1185 20.3432H12.1185L12.3499 19.9ZM19.25 11.043C19.25 13.3854 18.064 15.2868 16.6509 16.7216C15.238 18.1561 13.6389 19.0807 12.9186 19.4568L13.3815 20.3432C14.1468 19.9436 15.8476 18.9622 17.3633 17.4233C18.8788 15.8847 20.25 13.7454 20.25 11.043H19.25ZM12.75 4.5C16.337 4.5 19.25 7.42651 19.25 11.043H20.25C20.25 6.88 16.895 3.5 12.75 3.5V4.5ZM6.25 11.043C6.25 7.42651 9.16303 4.5 12.75 4.5V3.5C8.60498 3.5 5.25 6.88 5.25 11.043H6.25ZM12.5814 19.4568C11.8611 19.0807 10.262 18.1561 8.84912 16.7216C7.43596 15.2868 6.25 13.3854 6.25 11.043H5.25C5.25 13.7454 6.62124 15.8847 8.13667 17.4233C9.65241 18.9622 11.3532 19.9436 12.1185 20.3432L12.5814 19.4568ZM12.9186 19.4568C12.8097 19.5136 12.6903 19.5136 12.5814 19.4568L12.1185 20.3432C12.5175 20.5515 12.9825 20.5515 13.3815 20.3432L12.9186 19.4568ZM15.25 11.043C15.25 12.4368 14.1278 13.5614 12.75 13.5614V14.5614C14.6859 14.5614 16.25 12.9833 16.25 11.043H15.25ZM12.75 8.52457C14.1278 8.52457 15.25 9.64921 15.25 11.043H16.25C16.25 9.10271 14.6859 7.52457 12.75 7.52457V8.52457ZM10.25 11.043C10.25 9.64921 11.3722 8.52457 12.75 8.52457V7.52457C10.8141 7.52457 9.25 9.10271 9.25 11.043H10.25ZM12.75 13.5614C11.3722 13.5614 10.25 12.4368 10.25 11.043H9.25C9.25 12.9833 10.8141 14.5614 12.75 14.5614V13.5614Z"
          fill={"currentColor"}
        />
      </svg>
    </span>
  );
}

export default LocationIcon;
