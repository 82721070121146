import React from "react";
import { cn } from "@/utils/utils";
function PhoneOutlineIcon({ className }: { className?: string }) {
  return (
    <span className={cn("block w-4 h-4 text-gray-500", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7904 9.37377L14.3202 10.9035C14.5576 11.1409 14.5576 11.5258 14.3202 11.7632C13.0367 13.0467 11.0048 13.1911 9.55266 12.102L8.81418 11.5481C7.44501 10.5213 6.22874 9.30499 5.20187 7.93582L4.648 7.19734C3.55894 5.74525 3.70334 3.71332 4.98682 2.42985C5.22422 2.19245 5.60912 2.19245 5.84651 2.42985L7.37623 3.95956C7.76675 4.35008 7.76675 4.98325 7.37623 5.37377L6.93116 5.81884C6.82297 5.92703 6.79615 6.09231 6.86458 6.22916C7.65568 7.81137 8.93863 9.09432 10.5208 9.88542C10.6577 9.95385 10.823 9.92703 10.9312 9.81884L11.3762 9.37377C11.7668 8.98325 12.3999 8.98325 12.7904 9.37377Z"
          stroke="currentColor"
        />
      </svg>
    </span>
  );
}

export default PhoneOutlineIcon;
