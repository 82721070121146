import { cn } from "@/utils/utils";
import React from "react";

export default function PendingIcon({ className }: { className?: string }) {
  return (
    <span className={cn(className)}>
      <svg
        width={26}
        height={26}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={26} height={26} rx={13} fill="#23CE68" />
        <path
          d="M20.5 13C20.5 17.14 17.14 20.5 13 20.5C8.86 20.5 6.3325 16.33 6.3325 16.33M6.3325 16.33H9.7225M6.3325 16.33V20.08M5.5 13C5.5 8.86 8.83 5.5 13 5.5C18.0025 5.5 20.5 9.67 20.5 9.67M20.5 9.67V5.92M20.5 9.67H17.17"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
