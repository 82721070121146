import { cn } from "@/utils/utils";
import React from "react";

const ReturnIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-10 h-10 text-gray-500", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        viewBox="0 0 41 40"
        fill="none"
      >
        <path
          d="M7.16797 14.9899C7.16797 14.1724 7.16797 13.7636 7.32021 13.3961C7.47245 13.0285 7.76148 12.7395 8.33954 12.1614L10.9964 9.50458C11.5745 8.92652 11.8635 8.63749 12.231 8.48525C12.5986 8.33301 13.0073 8.33301 13.8248 8.33301H27.1778C27.9953 8.33301 28.404 8.33301 28.7716 8.48525C29.1391 8.63749 29.4281 8.92652 30.0062 9.50458L32.6631 12.1614C33.2411 12.7395 33.5302 13.0285 33.6824 13.3961C33.8346 13.7636 33.8346 14.1724 33.8346 14.9899V29.333C33.8346 31.2186 33.8346 32.1614 33.2489 32.7472C32.6631 33.333 31.7203 33.333 29.8346 33.333H11.168C9.28235 33.333 8.33954 33.333 7.75376 32.7472C7.16797 32.1614 7.16797 31.2186 7.16797 29.333V14.9899Z"
          stroke="currentColor"
        />
        <path
          d="M7.16797 16.667H33.8346"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M15.5 22.2656L15.1464 22.6192L14.7929 22.2656L15.1464 21.9121L15.5 22.2656ZM19.0156 30.5C18.7395 30.5 18.5156 30.2761 18.5156 30C18.5156 29.7239 18.7395 29.5 19.0156 29.5L19.0156 30.5ZM18.6621 26.1348L15.1464 22.6192L15.8536 21.9121L19.3692 25.4277L18.6621 26.1348ZM15.1464 21.9121L18.6621 18.3964L19.3692 19.1036L15.8536 22.6192L15.1464 21.9121ZM15.5 21.7656L22.8828 21.7656L22.8828 22.7656L15.5 22.7656L15.5 21.7656ZM22.8828 30.5L19.0156 30.5L19.0156 29.5L22.8828 29.5L22.8828 30.5ZM27.25 26.1328C27.25 28.5447 25.2947 30.5 22.8828 30.5L22.8828 29.5C24.7425 29.5 26.25 27.9925 26.25 26.1328L27.25 26.1328ZM22.8828 21.7656C25.2947 21.7656 27.25 23.7209 27.25 26.1328L26.25 26.1328C26.25 24.2732 24.7425 22.7656 22.8828 22.7656L22.8828 21.7656Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default ReturnIcon;
