// export { default as AddSquareIcon  }from "./AddSquareIcon"
// same with
// import AddSquareIcon from "./AddSquareIcon"
// export {AddSquareIcon}
export { default as AddSquareIcon } from "./AddSquareIcon";
export { default as ArrowLeftIcon } from "./ArrowLeftIcon";
export { default as ArrowRightIcon } from "./ArrowRightIcon";
export { default as BoxLightIcon } from "./BoxLightIcon";
export { default as CakeIcon } from "./CakeIcon";
export { default as CardAbout } from "./CardAbout";
export { default as CartIcon } from "./CartIcon";
export { default as CheckboxFilledIcon } from "./CheckboxFilledIcon";
export { default as CheckboxIcon } from "./CheckboxIcon";
export { default as CheckIcon } from "./CheckIcon";
export { default as CheckRoundedIcon } from "./CheckRoundedIcon";
export { default as CheckSquareIcon } from "./CheckSquareIcon";
export { default as CheckTagIcon } from "./CheckTagIcon";
export { default as ChevronLeftIcon } from "./ChevronLeftIcon";
export { default as ChevronDownIcon } from "./ChevronDownIcon";
export { default as ChevronRightIcon } from "./ChevronRightIcon";
export { default as ChevronsDownIcon } from "./ChevronsDownIcon";
export { default as ChevronUpIcon } from "./ChevronUpIcon";
export { default as CloseIcon } from "./CloseIcon";
export { default as CloseTagIcon } from "./CloseTagIcon";
export { default as ContactIcon } from "./ContactIcon";
export { default as CreditCardIcon } from "./CreditCardIcon";
export { default as DiamondIcon } from "./DiamondIcon";
export { default as EditIcon } from "./EditIcon";
export { default as ExchangeIcon } from "./ExchangeIcon";
export { default as EyeCloseIcon } from "./EyeCloseIcon";
export { default as EyeIcon } from "./EyeIcon";
export { default as FacebookFilledIcon } from "./FacebookFilledIcon";
export { default as FacebookIcon } from "./FacebookIcon";
export { default as FacebookOutlineIcon } from "./FacebookOutlineIcon";
export { default as FeatherIcon } from "./FeatherIcon";
export { default as FilterIcon } from "./FilterIcon";
export { default as GiftAbout } from "./GiftAbout";
export { default as GoogleFilledIcon } from "./GoogleFilledIcon";
export { default as HeartFilledIcon } from "./HeartFilledIcon";
export { default as HeartIcon } from "./HeartIcon";
export { default as HomeIcon } from "./HomeIcon";
export { default as InfoCircleIcon } from "./InfoCircleIcon";
export { default as InstagramIcon } from "./InstagramIcon";
export { default as LiveIcon } from "./LiveIcon";
export { default as LoadingIcon } from "./LoadingIcon";
export { default as LocationIcon } from "./LocationIcon";
export { default as LockIcon } from "./LockIcon";
export { default as LogoHeaderIcon } from "./LogoHeaderIcon";
export { default as MapPinFilledIcon } from "./MapPinFilledIcon";
export { default as MessageAbout } from "./MessageAbout";
export { default as MessageIcon } from "./MessageIcon";
export { default as MessengerIcon } from "./MessengerIcon";
export { default as MinusIcon } from "./MinusIcon";
export { default as MinusSquareIcon } from "./MinusSquareIcon";
export { default as NamperfumeFilledIcon } from "./NamperfumeFilledIcon";
export { default as NamperfumeIcon } from "./NamperfumeIcon";
export { default as NamperfumeTypoLogo } from "./NamperfumeTypoLogo";
export { default as NewsIcon } from "./NewsIcon";
export { default as NotedIcon } from "./NotedIcon";
export { default as NoTickIcon } from "./NoTickIcon";
export { default as NotificationIcon } from "./NotificationIcon";
export { default as PenIcon } from "./PenIcon";
export { default as PhoneIcon } from "./PhoneIcon";
export { default as PhoneFilledIcon } from "./PhoneFilledIcon";
export { default as PhoneOutlineIcon } from "./PhoneOutlineIcon";
export { default as PhotoIcon } from "./PhotoIcon";
export { default as PlusIcon } from "./PlusIcon";
export { default as PopupErrorIcon } from "./PopupErrorIcon";
export { default as RankSliderGoldIcon } from "./RankSliderGoldIcon";
export { default as RankSliderMobileGoldIcon } from "./RankSliderMobileGoldIcon";
export { default as RankSliderMemberIcon } from "./RankSliderMemberIcon";
export { default as RankSliderMobileMemberIcon } from "./RankSliderMobileMemberIcon";
export { default as RankSliderMobilePlatinumIcon } from "./RankSliderMobilePlatinumIcon";
export { default as RankSliderMobileNicheIcon } from "./RankSliderMobileNicheIcon";
export { default as RankSliderNicheIcon } from "./RankSliderNicheIcon";
export { default as RankSliderPlatinumIcon } from "./RankSliderPlatinumIcon";
export { default as ReturnArrowIcon } from "./ReturnArrowIcon";
export { default as ReturnIcon } from "./ReturnIcon";
export { default as SearchFilledIcon } from "./SearchFilledIcon";
export { default as SearchIcon } from "./SearchIcon";
export { default as ShareIcon } from "./ShareIcon";
export { default as ShieldIcon } from "./ShieldIcon";
export { default as ShopAboutIcon } from "./ShopAboutIcon";
export { default as SlideNextIcon } from "./SlideNextIcon";
export { default as SlidePrevIcon } from "./SlidePrevIcon";
export { default as SocialIcon } from "./SocialIcon";
export { default as SortDownIcon } from "./SortDownIcon";
export { default as StarFilledIcon } from "./StarFilledIcon";
export { default as StarIcon } from "./StarIcon";
export { default as StoreAboutIcon } from "./StoreAboutIcon";
export { default as StoreIcon } from "./StoreIcon";
export { default as ShieldAboutIcon } from "./ShieldAboutIcon";
export { default as SuccessIcon } from "./SuccessIcon";
export { default as SuccessOutlineIcon } from "./SuccessOutlineIcon";
export { default as TagDealIcon } from "./TagDealIcon";
export { default as TagFilledIcon } from "./TagFilledIcon";
export { default as ThumbUpCircleIcon } from "./ThumbUpCircleIcon";
export { default as TickIcon } from "./TickIcon";
export { default as TiktokIcon } from "./TiktokIcon";
export { default as TiktokOutlineIcon } from "./TiktokOutlineIcon";
export { default as TrophyIcon } from "./TrophyIcon";
export { default as TruckIcon } from "./TruckICon";
export { default as UserFilledIcon } from "./UserFilledIcon";
export { default as UserIcon } from "./UserIcon";
export { default as UserScanIcon } from "./UserScanIcon";
export { default as VoucherIcon } from "./VoucherIcon";
export { default as WishIcon } from "./WishIcon";
export { default as YoutubeIcon } from "./YoutubeIcon";
export { default as YoutubeOutlineIcon } from "./YoutubeOutlineIcon";
export { default as ZaloIcon } from "./ZaloIcon";
export { default as ZaloOutlineIcon } from "./ZaloOutlineIcon";
export { default as InstagramOutlineIcon } from "./InstagramOutlineIcon";
export { default as Share2Icon } from "./Share2Icon";
export { default as VietnamFlagIcon } from "./VietnamFlagIcon";
export { default as UnitedStateFlagIcon } from "./UnitedStateFlagIcon";
export { default as EmptyIcon } from "./EmptyIcon";
export { default as PendingIcon } from "./PendingIcon";
