import { cn } from "@/utils/utils";
import React from "react";

function PenIcon({ className }: { className?: string }) {
  return (
    <span className={cn("block w-6 h-6", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.92776 19.283L5.92777 19.283L5.94954 19.2775L5.94955 19.2775L8.58189 18.6194C8.59701 18.6156 8.61201 18.6119 8.6269 18.6082C8.84963 18.5528 9.04681 18.5037 9.22585 18.4023C9.40488 18.301 9.5484 18.1571 9.71053 17.9947C9.72137 17.9838 9.73229 17.9729 9.74332 17.9618L16.9374 10.7678L16.9374 10.7678L16.9607 10.7445C17.2741 10.4311 17.5442 10.1611 17.7313 9.91573C17.9319 9.65281 18.0838 9.36038 18.0838 9C18.0838 8.63961 17.9319 8.34719 17.7313 8.08427C17.5442 7.83894 17.2741 7.5689 16.9607 7.2555L16.9374 7.23223L16.5838 7.58579L16.9374 7.23223L16.7658 7.06066L16.7425 7.03738C16.4291 6.72395 16.1591 6.45388 15.9138 6.2667C15.6509 6.0661 15.3584 5.91421 14.998 5.91421C14.6377 5.91421 14.3452 6.0661 14.0823 6.2667C13.837 6.45388 13.567 6.72395 13.2535 7.03739L13.2303 7.06066L6.03621 14.2547C6.02518 14.2658 6.01424 14.2767 6.00337 14.2875C5.8409 14.4496 5.69708 14.5932 5.59571 14.7722C5.49435 14.9512 5.44527 15.1484 5.38984 15.3711C5.38613 15.386 5.3824 15.401 5.37862 15.4162L4.71509 18.0703C4.71287 18.0791 4.71064 18.088 4.7084 18.097C4.66916 18.2537 4.62725 18.421 4.61351 18.5615C4.59837 18.7163 4.6019 18.9773 4.81131 19.1867C5.02072 19.3961 5.28177 19.3997 5.4365 19.3845C5.57704 19.3708 5.74437 19.3289 5.90106 19.2896C5.90999 19.2874 5.9189 19.2852 5.92776 19.283Z"
          stroke="currentColor"
        />
        <path
          d="M12.498 7.5L15.498 5.5L18.498 8.5L16.498 11.5L12.498 7.5Z"
          fill={"currentColor"}
        />
      </svg>
    </span>
  );
}

export default PenIcon;
