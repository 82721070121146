import { cn } from "@/utils/utils";
import React from "react";

const StoreIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-6 h-6 text-gray-500", className)}>
      <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 27 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8073 21V16C15.8073 15.4477 15.3595 15 14.8073 15H11.3564C10.8042 15 10.3564 15.4477 10.3564 16V21"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.45117 11V17C5.45117 18.8856 5.45117 19.8284 6.03696 20.4142C6.62274 21 7.56555 21 9.45117 21H16.7135C18.5991 21 19.5419 21 20.1277 20.4142C20.7135 19.8284 20.7135 18.8856 20.7135 17V11"
          stroke="currentColor"
        />
        <path
          d="M5.04908 4.4741C5.2423 3.76516 5.33891 3.41069 5.60771 3.20535C5.87651 3 6.24391 3 6.9787 3H19.1853C19.9201 3 20.2875 3 20.5563 3.20535C20.8251 3.41069 20.9217 3.76516 21.1149 4.4741L22.3895 9.15091C22.5339 9.68087 22.6062 9.94585 22.5716 10.1595C22.5182 10.4901 22.3028 10.772 21.9979 10.9105C21.8009 11 21.5262 11 20.9769 11H20.8259C20.2358 11 19.9408 11 19.6882 10.9328C19.1178 10.7812 18.6435 10.3853 18.3923 9.85118C18.2811 9.61468 18.2284 9.3244 18.1229 8.74384V8.74384C18.0952 8.59151 18.0814 8.51535 18.0642 8.495C18.0243 8.44751 17.9512 8.44751 17.9112 8.495C17.8941 8.51535 17.8802 8.59151 17.8526 8.74384L17.7837 9.12295C17.7434 9.34455 17.7233 9.45536 17.6959 9.55093C17.4702 10.3386 16.7869 10.9089 15.9716 10.9901C15.8727 11 15.7601 11 15.5349 11V11C15.3096 11 15.197 11 15.0981 10.9901C14.2828 10.9089 13.5995 10.3386 13.3738 9.55093C13.3464 9.45536 13.3263 9.34455 13.286 9.12294L13.2171 8.74384C13.1895 8.59151 13.1756 8.51535 13.1585 8.495C13.1185 8.44751 13.0454 8.44751 13.0055 8.495C12.9883 8.51535 12.9745 8.59151 12.9468 8.74384L12.8779 9.12295C12.8377 9.34455 12.8176 9.45536 12.7902 9.55093C12.5645 10.3386 11.8812 10.9089 11.0659 10.9901C10.967 11 10.8543 11 10.6291 11V11C10.4039 11 10.2913 11 10.1923 10.9901C9.37703 10.9089 8.69376 10.3386 8.46806 9.55093C8.44067 9.45536 8.42054 9.34455 8.38028 9.12294L8.3114 8.74384C8.28372 8.59151 8.26988 8.51535 8.25276 8.495C8.2128 8.44751 8.13969 8.44751 8.09973 8.495C8.08261 8.51535 8.06877 8.59151 8.04109 8.74384V8.74384C7.93561 9.3244 7.88287 9.61468 7.77164 9.85118C7.52044 10.3853 7.04615 10.7812 6.47573 10.9328C6.22316 11 5.92813 11 5.33807 11H5.18705C4.63775 11 4.36311 11 4.16606 10.9105C3.86112 10.772 3.64574 10.4901 3.59232 10.1595C3.55781 9.94585 3.63003 9.68087 3.77446 9.15091L5.04908 4.4741Z"
          stroke="currentColor"
        />
      </svg>
    </span>
  );
};

export default StoreIcon;
