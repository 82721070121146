import { cn } from "@/utils/utils";
import React from "react";

const YoutubeIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-12 h-12", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24Z"
          fill="#FF0000"
        />
        <path
          d="M35.4 18.1501C35.1 17.1001 34.35 16.3501 33.3 16.0501C31.5 15.6001 23.85 15.6001 23.85 15.6001C23.85 15.6001 16.35 15.6001 14.4 16.0501C13.35 16.3501 12.6 17.1001 12.3 18.1501C12 20.1001 12 24.0001 12 24.0001C12 24.0001 12 27.9001 12.45 29.8501C12.75 30.9001 13.5 31.6501 14.55 31.9501C16.35 32.4001 24 32.4001 24 32.4001C24 32.4001 31.5 32.4001 33.45 31.9501C34.5 31.6501 35.25 30.9001 35.55 29.8501C36 27.9001 36 24.0001 36 24.0001C36 24.0001 36 20.1001 35.4 18.1501ZM21.6 27.6001V20.4001L27.9 24.0001L21.6 27.6001Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export default YoutubeIcon;
