"use client";
import { cn } from "@/utils/utils";
import React from "react";

const PhoneIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-12 h-12 text-red-500", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="currentColor" />
        <path
          d="M35.964 31.596C35.964 32.028 35.868 32.472 35.664 32.904C35.46 33.336 35.196 33.744 34.848 34.128C34.26 34.776 33.612 35.244 32.88 35.544C32.16 35.844 31.38 36 30.54 36C29.316 36 28.008 35.712 26.628 35.124C25.248 34.536 23.868 33.744 22.5 32.748C21.12 31.74 19.812 30.624 18.564 29.388C17.328 28.14 16.212 26.832 15.216 25.464C14.232 24.096 13.44 22.728 12.864 21.372C12.288 20.004 12 18.696 12 17.448C12 16.632 12.144 15.852 12.432 15.132C12.72 14.4 13.176 13.728 13.812 13.128C14.58 12.372 15.42 12 16.308 12C16.644 12 16.98 12.072 17.28 12.216C17.592 12.36 17.868 12.576 18.084 12.888L20.868 16.812C21.084 17.112 21.24 17.388 21.348 17.652C21.456 17.904 21.516 18.156 21.516 18.384C21.516 18.672 21.432 18.96 21.264 19.236C21.108 19.512 20.88 19.8 20.592 20.088L19.68 21.036C19.548 21.168 19.488 21.324 19.488 21.516C19.488 21.612 19.5 21.696 19.524 21.792C19.56 21.888 19.596 21.96 19.62 22.032C19.836 22.428 20.208 22.944 20.736 23.568C21.276 24.192 21.852 24.828 22.476 25.464C23.124 26.1 23.748 26.688 24.384 27.228C25.008 27.756 25.524 28.116 25.932 28.332C25.992 28.356 26.064 28.392 26.148 28.428C26.244 28.464 26.34 28.476 26.448 28.476C26.652 28.476 26.808 28.404 26.94 28.272L27.852 27.372C28.152 27.072 28.44 26.844 28.716 26.7C28.992 26.532 29.268 26.448 29.568 26.448C29.796 26.448 30.036 26.496 30.3 26.604C30.564 26.712 30.84 26.868 31.14 27.072L35.112 29.892C35.424 30.108 35.64 30.36 35.772 30.66C35.892 30.96 35.964 31.26 35.964 31.596Z"
          fill="white"
        />
        <path
          d="M31.7996 20.4C31.7996 19.68 31.2356 18.576 30.3956 17.676C29.6276 16.848 28.6076 16.2 27.5996 16.2"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.9996 20.4C35.9996 15.756 32.2436 12 27.5996 12"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default PhoneIcon;
