import { cn } from "@/utils/utils";
import React from "react";

const GiftAbout = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-[72px] h-[72px] text-gray-500", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="72" height="72" rx="36" fill="white" />
        <path
          d="M21 31.333C21 30.3902 21 29.9188 21.2929 29.6259C21.5858 29.333 22.0572 29.333 23 29.333H49C49.9428 29.333 50.4142 29.333 50.7071 29.6259C51 29.9188 51 30.3902 51 31.333V35.6663C51 36.6091 51 37.0806 50.7071 37.3734C50.4142 37.6663 49.9428 37.6663 49 37.6663H48C47.0572 37.6663 46.5858 37.6663 46.2929 37.9592C46 38.2521 46 38.7235 46 39.6663V47.333C46 48.2758 46 48.7472 45.7071 49.0401C45.4142 49.333 44.9428 49.333 44 49.333H28C27.0572 49.333 26.5858 49.333 26.2929 49.0401C26 48.7472 26 48.2758 26 47.333V39.6663C26 38.7235 26 38.2521 25.7071 37.9592C25.4142 37.6663 24.9428 37.6663 24 37.6663H23C22.0572 37.6663 21.5858 37.6663 21.2929 37.3734C21 37.0806 21 36.6091 21 35.6663V31.333Z"
          stroke="currentColor"
        />
        <path
          d="M24.334 37.667H47.6673"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M36 27.667L36 49.3337"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M36.0007 27.6667L33.6702 25.3362C32.4626 24.1287 30.9906 23.2189 29.3705 22.6788L26.9664 21.8775C25.6714 21.4458 24.334 22.4097 24.334 23.7749V26.2251C24.334 27.086 24.8848 27.8503 25.7015 28.1225L29.334 29.3333"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M35.9993 27.6667L38.3298 25.3362C39.5374 24.1287 41.0094 23.2189 42.6295 22.6788L45.0336 21.8775C46.3286 21.4458 47.666 22.4097 47.666 23.7749V26.2251C47.666 27.086 47.1152 27.8503 46.2985 28.1225L42.666 29.3333"
          stroke="currentColor"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};

export default GiftAbout;
