import { cn } from "@/utils/utils";
import React from "react";

const TagDealIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block text-red-500", className)}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.7105 3.24377L7.77085 3.52661C7.52237 3.53845 7.28721 3.64247 7.11131 3.81838L1.02072 9.90896C0.630198 10.2995 0.630199 10.9327 1.02072 11.3232L6.67758 16.98C7.0681 17.3706 7.70127 17.3706 8.09179 16.98L14.1824 10.8894C14.3583 10.7135 14.4623 10.4784 14.4741 10.2299L14.757 4.29021C14.785 3.70105 14.2997 3.21572 13.7105 3.24377Z"
          fill="#D72229"
        />
        <path
          d="M11.0756 8.06448C10.5239 8.09033 10.0557 7.66405 10.0299 7.11237C10.004 6.56069 10.4303 6.09252 10.982 6.06667C11.5337 6.04083 12.0018 6.4671 12.0277 7.01878C12.0535 7.57046 11.6272 8.03864 11.0756 8.06448Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export default TagDealIcon;
