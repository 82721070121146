import { cn } from "@/utils/utils";
import React from "react";

const NamperfumeTypoLogo = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-[123px] h-[24px] text-red-500", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        viewBox="0 0 123 24"
        fill="none"
      >
        <path
          d="M0 17.151L0.00421813 5.96302H1.50587C2.08798 5.96302 2.2567 6.12957 2.2567 6.67918V7.92414H2.32841C3.37029 6.49181 4.58089 5.77148 5.8632 5.77148C7.89634 5.77148 9.06055 7.03726 9.06055 9.62295V18.0129H7.51249C7.0021 18.0129 6.80806 17.7964 6.80806 17.151V10.1476C6.80806 8.49874 6.20487 7.82837 5.18408 7.82837C4.38686 7.82837 3.22265 8.6403 2.25248 10.2433V18.0129H0.704428C0.194034 18.0087 0 17.7964 0 17.151Z"
          fill="currentColor"
        />
        <path
          d="M11.6719 14.9523C11.6719 12.7039 13.6586 10.8635 16.6619 10.8635C17.4128 10.8635 17.9949 10.9134 18.4799 11.0092V9.59771C18.4799 8.23616 17.7291 7.49502 16.3751 7.49502C14.8734 7.49502 14.1479 8.09043 14.1479 9.00229V9.74344H13.0807C12.5957 9.74344 12.2329 9.33539 12.2329 8.59424C12.2329 7.18274 13.8569 5.77539 16.7843 5.77539C19.4248 5.77539 20.7071 7.09113 20.7071 9.26877V18.021H19.3278C18.7963 18.021 18.577 17.8294 18.577 17.3756V16.5845H18.5053C17.5857 17.6837 16.5185 18.1875 14.9916 18.1875C13.1229 18.1792 11.6677 16.9592 11.6719 14.9523ZM18.4757 14.6442V12.5165C17.8936 12.4207 17.3368 12.3708 16.6577 12.3708C15.0126 12.3708 13.9708 13.3492 13.9708 14.69C13.9708 15.8142 14.6963 16.4346 15.6918 16.4346C16.8264 16.4346 17.9189 15.6934 18.4757 14.6442Z"
          fill="currentColor"
        />
        <path
          d="M24.2656 17.1549L24.2698 5.96692H25.7715C26.3536 5.96692 26.5223 6.13347 26.5223 6.68309V7.92805H26.594C27.6359 6.49572 28.8465 5.77539 30.1288 5.77539C31.6769 5.77539 32.6724 6.49155 33.1321 7.92805H33.2038C34.2457 6.49572 35.5069 5.77539 36.7893 5.77539C38.8224 5.77539 40.033 7.04117 40.033 9.62685V18.0168H38.4849C37.9745 18.0168 37.7805 17.8003 37.7805 17.1549V10.1515C37.7805 8.45268 37.1014 7.83228 36.0848 7.83228C35.3087 7.83228 34.2457 8.47766 33.322 9.90999V18.0126H31.7697C31.2593 18.0126 31.0695 17.7961 31.0695 17.1507V10.1473C31.0695 8.44851 30.441 7.82812 29.4244 7.82812C28.623 7.82812 27.4883 8.64005 26.5181 10.2431V18.0126H24.9701C24.4597 18.0168 24.2656 17.8003 24.2656 17.1549Z"
          fill="currentColor"
        />
        <path
          d="M43.5938 22.9919L43.598 5.97057H45.0996C45.6817 5.97057 45.8505 6.13712 45.8505 6.66592V7.45703H45.9222C46.8417 6.38278 47.8583 5.7832 49.2165 5.7832C51.469 5.7832 53.2111 7.43204 53.2111 11.6666C53.2111 15.8511 50.811 18.2411 47.8583 18.2411C46.964 18.2411 46.403 18.1454 45.8462 17.9288V23.9996H44.2982C43.7878 23.9954 43.5938 23.7789 43.5938 22.9919ZM50.9122 11.9247C50.9122 8.7228 49.7986 7.76514 48.6133 7.76514C47.6432 7.76514 46.6266 8.43551 45.8505 9.53473V16.2758C46.4326 16.5132 47.0104 16.6089 47.7402 16.6089C49.457 16.6089 50.9122 15.2474 50.9122 11.9247Z"
          fill="currentColor"
        />
        <path
          d="M55.1719 12.0661C55.1719 7.93154 57.5467 5.75391 60.1155 5.75391C62.6591 5.75391 64.5952 7.4777 64.5952 11.1834C64.5952 11.9954 64.2788 12.4492 63.3129 12.4492H57.475C57.5214 15.3638 58.8796 16.4422 60.6006 16.4422C61.9546 16.4422 63.0935 16.2257 64.1354 15.7969L64.3547 16.3465C64.4982 16.7045 64.4011 17.1376 64.0384 17.3749C63.2622 17.8995 62.0263 18.1868 60.3096 18.1868C57.3273 18.1868 55.1719 16.3465 55.1719 12.0661ZM62.368 10.8753C62.368 8.5561 61.3978 7.45688 60.0185 7.45688C58.7109 7.45688 57.5467 8.69767 57.475 10.8753H62.368Z"
          fill="currentColor"
        />
        <path
          d="M67.2032 17.1167L67.2074 5.97448H68.6838C69.2659 5.97448 69.4599 6.14103 69.4599 6.76559V8.53518H69.5569C70.4765 6.52826 71.5183 5.78711 72.6108 5.78711C72.8513 5.78711 73.0453 5.83707 73.2182 5.93284C73.5557 6.12437 73.6527 6.41167 73.581 6.86552L73.4376 7.93976C73.1972 7.86898 72.8091 7.844 72.5433 7.844C71.5985 7.844 70.3162 8.89743 69.4683 11.1167V18.0243H67.9203C67.393 18.0243 67.199 17.8078 67.2032 17.1167Z"
          fill="currentColor"
        />
        <path
          d="M75.5875 17.1671V7.60299H74.3516V5.97913H75.5875V3.70573C75.5875 1.41151 77.0891 0 79.1476 0C79.9954 0 80.3835 0.0957661 80.7926 0.337263C81.1301 0.528795 81.2018 0.861895 81.109 1.29492L80.9403 2.01109C80.3835 1.81956 79.8731 1.72379 79.2699 1.72379C78.3504 1.72379 77.84 2.24842 77.84 3.4226V5.97913H80.6239V6.72028C80.6239 7.31986 80.3329 7.60299 79.6327 7.60299H77.84V18.0248H76.2919C75.8068 18.0248 75.5875 17.8125 75.5875 17.1671Z"
          fill="currentColor"
        />
        <path
          d="M82.3438 14.3934V5.97852H83.8454C84.3558 5.97852 84.5962 6.14507 84.5962 6.81543V13.8688C84.5962 15.5426 85.2753 16.213 86.1696 16.213C87.0174 16.213 88.0846 15.426 89.0295 13.7522V5.98268H90.5311C91.0626 5.98268 91.2819 6.22001 91.2819 6.86539V18.0284H89.755C89.2446 18.0284 89.0295 17.8119 89.0295 17.1665V16.0922H88.9325C87.9159 17.5496 86.8234 18.1949 85.5664 18.1949C83.5544 18.1949 82.3438 16.95 82.3438 14.3934Z"
          fill="currentColor"
        />
        <path
          d="M95.0547 17.1705L95.0589 5.98255H96.5606C97.1427 5.98255 97.3114 6.1491 97.3114 6.69871V7.94367H97.3831C98.425 6.51134 99.6356 5.79102 100.922 5.79102C102.47 5.79102 103.466 6.50718 103.925 7.94367H103.997C105.039 6.51134 106.3 5.79102 107.583 5.79102C109.616 5.79102 110.826 7.05679 110.826 9.64248V18.0324H109.278C108.768 18.0324 108.574 17.8159 108.574 17.1705V10.1671C108.574 8.4683 107.895 7.8479 106.878 7.8479C106.102 7.8479 105.039 8.49328 104.115 9.92561V18.0283H102.567C102.057 18.0283 101.863 17.8117 101.863 17.1664V10.1629C101.863 8.46414 101.234 7.84374 100.218 7.84374C99.4204 7.84374 98.2816 8.65567 97.3114 10.2587V18.0283H95.7633C95.2487 18.0283 95.0547 17.8159 95.0547 17.1705Z"
          fill="currentColor"
        />
        <path
          d="M113.469 12.0779C113.469 7.94326 115.844 5.76562 118.412 5.76562C120.956 5.76562 122.892 7.48941 122.892 11.1951C122.892 12.0071 122.576 12.4609 121.61 12.4609H115.772C115.818 15.3797 117.176 16.454 118.893 16.454C120.252 16.454 121.386 16.2374 122.428 15.8086L122.647 16.3582C122.791 16.7163 122.694 17.1493 122.331 17.3866C121.555 17.9113 120.319 18.1986 118.602 18.1986C115.624 18.1986 113.469 16.3582 113.469 12.0779ZM120.661 10.887C120.661 8.56782 119.695 7.4686 118.311 7.4686C117.004 7.4686 115.839 8.70939 115.768 10.887H120.661Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default NamperfumeTypoLogo;
