import { ImageLoaderProps } from "next/image";
import clsx from "clsx";
import { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { FileJson } from "@/common/types/File.type";
import { Locale } from "./detectLangForServer";
import Helper from "./helper";

type AnyObject = { [key: string]: any };
export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs));
};

export const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
	return `https://devnteam.beme.vn/imgs/${src}`;
};

export const imageUrlFromArray = (files: FileJson[]) => {
	if (files.length > 0) {
		return;
	}
};

export const checkParse = <T>(
	data: string,
	from?: string | number | object
) => {
	try {
		const dataParse = JSON.parse(data) as T;
	} catch (error) {
		throw new Error(JSON.stringify({ data: data, from: from }));
	}
};

export const parseData = <T>(data: T): T => {
	const dataJson = JSON.stringify(data);
	let dataParse = data;
	try {
		dataParse = JSON.parse(dataJson) as T;
	} catch (error) {
		// return data
		throw new Error(JSON.stringify({ data: data }));
	}

	return dataParse;
};

export const parseSettingValue = <T>(value: string, key: string): T | null => {
	try {
		const valueParse = JSON.parse(value) as T;
		if (valueParse) {
			return valueParse;
		}
	} catch (error) {
		throw new Error(`parse JSON from key:" ${key} " faild .`);
	}
	return null;
};

export const devTools = () => {
	return false;
};

export const initArray = <T>(current: number, defaultData: T) => {
	return Array.from(new Array(current)).map((i) => defaultData);
};

export function parserHtml(string: string): string {
	if (!Helper.isServer()) {
		const parser = new DOMParser();
		const htmlDoc = parser.parseFromString(
			"<!doctype html><body>" + string,
			"text/html"
		);

		return htmlDoc.body.textContent as string;
	}
	return string;
}

export function showTranslate<T>(resource: T, key: keyof T, lang: Locale) {
	const value = resource?.[key] as any;
	const text = value?.[lang];
	// if (typeof text === "undefined") return `Translate missing ${lang}:${key.toString()}`
	if (typeof text === "undefined") return "";

	return text;
}

export async function delay(timeout: number) {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(1);
		}, timeout);
	});
}

export function mergeObjects(obj1: AnyObject, obj2: AnyObject): AnyObject {
	const result: AnyObject = {};

	// Get all unique keys from both objects
	const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

	allKeys.forEach((key) => {
		if (obj1[key] !== undefined && obj1[key] !== 0 && obj1[key] !== "") {
			result[key] = obj1[key];
		}
		if (obj2[key] !== undefined && obj2[key] !== 0 && obj2[key] !== "") {
			result[key] = obj2[key];
		}
	});

	return result;
}

export function getHostName(host?: string) {
	const markAsEmptyHostnameItems = [
		"localhost",
		"betaapp.cropany.com",
		"localhost:3001",
	];
	const hostname = host ?? window.location.hostname;
	if (hostname === "" || markAsEmptyHostnameItems.includes(hostname)) {
		return process.env.REACT_APP_HOST_ADMIN;
	} else {
		// return hostname;
		return process.env.REACT_APP_HOST_ADMIN;
	}
}
export const isDefaultData = true;
