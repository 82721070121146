import React from "react";
import { cn } from "@/utils/utils";

const ThumbUpCircleIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-6 h-6 text-yellow-500 ", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7499 2.45031C11.4399 1.86031 12.5699 1.86031 13.2699 2.45031L14.8499 3.81042C15.1499 4.07042 15.7099 4.28039 16.1099 4.28039H17.8099C18.8699 4.28039 19.7399 5.15032 19.7399 6.21032V7.9104C19.7399 8.3004 19.9499 8.87041 20.2099 9.17041L21.5699 10.7504C22.1599 11.4404 22.1599 12.5704 21.5699 13.2704L20.2099 14.8503C19.9499 15.1503 19.7399 15.7103 19.7399 16.1103V17.8104C19.7399 18.8704 18.8699 19.7404 17.8099 19.7404H16.1099C15.7199 19.7404 15.1499 19.9503 14.8499 20.2103L13.2699 21.5703C12.5799 22.1603 11.4499 22.1603 10.7499 21.5703L9.16989 20.2103C8.86989 19.9503 8.30988 19.7404 7.90988 19.7404H6.17987C5.11987 19.7404 4.24988 18.8704 4.24988 17.8104V16.1003C4.24988 15.7103 4.03989 15.1503 3.78989 14.8503L2.43988 13.2604C1.85988 12.5704 1.85988 11.4504 2.43988 10.7604L3.78989 9.17041C4.03989 8.87041 4.24988 8.31041 4.24988 7.92041V6.21032C4.24988 5.15032 5.11987 4.28039 6.17987 4.28039H7.90988C8.29988 4.28039 8.86989 4.07042 9.16989 3.81042L10.7499 2.45031Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.89014 14.82L11.3401 15.94C11.5301 16.13 11.9501 16.22 12.2301 16.22H14.0001C14.5601 16.22 15.1701 15.8 15.3101 15.24L16.4301 11.82C16.6601 11.17 16.2401 10.6 15.5401 10.6H13.6701C13.3901 10.6 13.1601 10.3701 13.2001 10.0401L13.4301 8.54008C13.5201 8.12008 13.2401 7.65005 12.8201 7.51005C12.4501 7.37005 11.9801 7.56 11.7901 7.84L9.87012 10.69"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M7.5 14.8196V10.2396C7.5 9.57962 7.78 9.34961 8.44 9.34961H8.91C9.56 9.34961 9.85001 9.57962 9.85001 10.2396V14.8196C9.85001 15.4696 9.57 15.7096 8.91 15.7096H8.44C7.78 15.7096 7.5 15.4796 7.5 14.8196Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default ThumbUpCircleIcon;
