import { cn } from "@/utils/utils";
import React from "react";

function FacebookOutlineIcon({ className }: { className?: string }) {
  return (
    <span className={cn("block w-4 h-4 text-gray-200", className)}>
      <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.7993 20.7736V12.5537H8.63832C8.28566 12.5537 8 12.2673 8 11.9154V9.31269C8 8.96214 8.28707 8.67508 8.63832 8.67508H9.7993V6.58237C9.7993 4.60392 11.4039 3 13.3824 3H16.3617C16.7143 3 17 3.28566 17 3.63832V6.24028C17 6.59294 16.7143 6.8786 16.3617 6.8786H14.5984C14.3973 6.8786 14.2316 7.04435 14.2316 7.24537V8.67649L16.3229 8.67508C16.6756 8.69976 16.9612 8.93746 16.9612 9.31269C16.8991 10.1944 16.8131 11.0852 16.7383 11.9683C16.7108 12.2976 16.4336 12.5537 16.1028 12.5537H14.2316V20.7736C14.2316 21.1241 13.9452 21.4112 13.594 21.4112L10.4376 21.4119C10.0857 21.4119 9.7993 21.1255 9.7993 20.7736ZM10.633 20.5796H13.3979V11.72H15.9237L16.1106 9.50666H13.3979V7.24537C13.3979 6.58236 13.9353 6.0449 14.5984 6.0449H16.1663V3.8337H13.3824C11.8638 3.8337 10.633 5.0645 10.633 6.58237V9.50807H8.8337V11.72H10.633V20.5796Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

export default FacebookOutlineIcon;
