import { cn } from "@/utils/utils";
import React from "react";

const MessengerIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-12 h-12", className)}>
      <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.9993 0C10.4817 0 0 9.90573 0 23.2794C0 30.2752 2.86792 36.323 7.53579 40.4989C7.92578 40.8469 8.16578 41.3389 8.17778 41.8668L8.30977 46.1387C8.31938 46.4531 8.40605 46.7602 8.56216 47.0332C8.71828 47.3062 8.93905 47.5366 9.20508 47.7043C9.47111 47.872 9.77426 47.9717 10.0879 47.9948C10.4015 48.0179 10.716 47.9636 11.0037 47.8367L15.7676 45.7367C16.1696 45.5567 16.6255 45.5267 17.0515 45.6407C19.2415 46.2407 21.5694 46.5647 23.9993 46.5647C37.517 46.5647 47.9987 36.659 47.9987 23.2854C47.9987 9.91173 37.517 0 23.9993 0Z"
          fill="url(#paint0_radial_348_7240)"
        />
        <path
          d="M9.58729 30.0891L16.6371 18.9054C16.9023 18.4843 17.2519 18.1228 17.6639 17.8437C18.0758 17.5646 18.5412 17.3739 19.0306 17.2837C19.5199 17.1935 20.0227 17.2057 20.5071 17.3196C20.9915 17.4335 21.447 17.6466 21.8449 17.9454L27.4548 22.1513C27.7052 22.3388 28.0099 22.4396 28.3228 22.4385C28.6356 22.4375 28.9396 22.3345 29.1887 22.1453L36.7605 16.3974C37.7685 15.6295 39.0885 16.8414 38.4165 17.9154L31.3607 29.0931C31.0955 29.5142 30.7459 29.8757 30.3339 30.1548C29.9219 30.4339 29.4566 30.6246 28.9672 30.7148C28.4778 30.805 27.9751 30.7928 27.4907 30.6789C27.0063 30.565 26.5507 30.3519 26.1528 30.0531L20.543 25.8472C20.2925 25.6597 19.9879 25.5589 19.675 25.5599C19.3622 25.561 19.0582 25.664 18.809 25.8532L11.2372 31.601C10.2293 32.369 8.9093 31.163 9.58729 30.0891Z"
          fill="white"
        />
        <defs>
          <radialGradient
            id="paint0_radial_348_7240"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(8.03978 47.9987) scale(52.7985 52.7986)"
          >
            <stop stopColor="#0099FF" />
            <stop offset="0.6" stopColor="#A033FF" />
            <stop offset="0.9" stopColor="#FF5280" />
            <stop offset="1" stopColor="#FF7061" />
          </radialGradient>
        </defs>
      </svg>
      ;
    </span>
  );
};

export default MessengerIcon;
