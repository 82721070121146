import { cn } from "@/utils/utils";
import React from "react";

const CartIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-6 h-6 text-gray-500", className)}>
      <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3574 15.4373C19.1733 15.4294 19.8867 14.924 20.096 14.199L22.108 7.32601C22.1721 7.10265 22.0311 6.87538 21.7876 6.81269C21.7492 6.80485 21.7107 6.79701 21.6723 6.79701H6.53733L5.50783 3.30956C5.45657 3.12931 5.27715 3 5.07211 3H2.50049V3.83464H4.71328L5.7086 7.24372C5.70433 7.27115 5.70433 7.29858 5.7086 7.32601L7.75906 14.2225C7.77188 14.2774 7.79324 14.3362 7.8146 14.391L8.50663 16.7029C7.84023 17.0282 7.38742 17.6708 7.38742 18.4114C7.38742 19.4812 8.33148 20.3471 9.49768 20.3471C10.6639 20.3471 11.6079 19.4812 11.6079 18.4114C11.6079 18.0783 11.514 17.7609 11.3516 17.4866C11.3132 17.4239 11.2747 17.3612 11.2278 17.2986H16.5974C16.3582 17.612 16.2129 17.996 16.2129 18.4114C16.2129 19.4812 17.157 20.3471 18.3232 20.3471C19.4894 20.3471 20.4335 19.4812 20.4335 18.4114C20.4335 17.9059 20.2241 17.4475 19.8781 17.1026C19.5065 16.7147 18.9597 16.4678 18.3488 16.4639H9.41652L9.09613 15.3863C9.23283 15.4177 9.3738 15.4373 9.51477 15.4373H18.3574ZM18.3275 17.2986C18.9981 17.2986 19.5407 17.7962 19.5407 18.4114C19.5407 19.0266 18.9981 19.5243 18.3275 19.5243C17.6568 19.5243 17.1143 19.0266 17.1143 18.4114C17.1143 17.7962 17.6568 17.2986 18.3275 17.2986ZM9.49768 17.2986C10.1684 17.2986 10.7066 17.8001 10.7066 18.4114C10.7066 19.0266 10.1641 19.5243 9.49341 19.5243C8.82274 19.5243 8.28449 19.0266 8.28449 18.4114C8.28449 17.7962 8.82701 17.2986 9.49768 17.2986ZM8.68604 14.0736L7.66508 10.6489L6.75519 7.63949H21.0614L19.2117 14.0109C19.1092 14.3754 18.7546 14.6301 18.3446 14.6379H9.50622C9.13458 14.6183 8.80992 14.3989 8.68604 14.0736Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default CartIcon;
