import { cn } from "@/utils/utils";
import React from "react";

const ContactIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-[60px] h-[60px] text-gray-500 ", className)}>
      <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="30" cy="30" r="30" fill="#F6E9E9" />
        <g clipPath="url(#clip0_340_19567)">
          <path
            d="M29.2284 9.1377C29.2177 9.1377 29.1963 9.1377 29.1641 9.1377C18.3183 9.1377 9.49805 17.9579 9.49805 28.8037C9.49805 33.2299 11.0092 37.5275 13.7742 40.9892L10.9127 47.6446C10.6769 48.1912 10.9342 48.8235 11.47 49.0485C11.6629 49.1343 11.8773 49.1557 12.0809 49.1236L22.573 47.2802C24.6736 48.0411 26.8813 48.4269 29.1105 48.4162C39.9563 48.4162 48.7766 39.596 48.7766 28.7502C48.798 17.9365 40.0421 9.14841 29.2284 9.1377ZM22.498 24.8491H28.2853C28.8747 24.8491 29.357 25.3314 29.357 25.9208C29.357 26.5103 28.8747 26.9925 28.2853 26.9925H22.498C21.9086 26.9925 21.4263 26.5103 21.4263 25.9208C21.4263 25.3314 21.9086 24.8491 22.498 24.8491ZM35.7873 32.3511H22.498C21.9086 32.3511 21.4263 31.8689 21.4263 31.2794C21.4263 30.69 21.9086 30.2077 22.498 30.2077H35.7873C36.3768 30.2077 36.859 30.69 36.859 31.2794C36.859 31.8689 36.3768 32.3511 35.7873 32.3511Z"
            fill="#D72229"
          />
        </g>
        <defs>
          <clipPath id="clip0_340_19567">
            <rect
              width="40"
              height="40"
              fill="white"
              transform="translate(9.1377 9.1377)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default ContactIcon;
