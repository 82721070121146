import React from "react";

const PopupErrorIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9997 3.33325C10.8163 3.33325 3.33301 10.8166 3.33301 19.9999C3.33301 29.1833 10.8163 36.6666 19.9997 36.6666C29.183 36.6666 36.6663 29.1833 36.6663 19.9999C36.6663 10.8166 29.183 3.33325 19.9997 3.33325ZM18.7497 13.3333C18.7497 12.6499 19.3163 12.0833 19.9997 12.0833C20.683 12.0833 21.2497 12.6499 21.2497 13.3333V21.6666C21.2497 22.3499 20.683 22.9166 19.9997 22.9166C19.3163 22.9166 18.7497 22.3499 18.7497 21.6666V13.3333ZM21.533 27.2999C21.4497 27.5166 21.333 27.6833 21.183 27.8499C21.0163 27.9999 20.833 28.1166 20.633 28.1999C20.433 28.2833 20.2163 28.3333 19.9997 28.3333C19.783 28.3333 19.5663 28.2833 19.3663 28.1999C19.1663 28.1166 18.983 27.9999 18.8163 27.8499C18.6663 27.6833 18.5497 27.5166 18.4663 27.2999C18.383 27.0999 18.333 26.8833 18.333 26.6666C18.333 26.4499 18.383 26.2333 18.4663 26.0333C18.5497 25.8333 18.6663 25.6499 18.8163 25.4833C18.983 25.3333 19.1663 25.2166 19.3663 25.1333C19.7663 24.9666 20.233 24.9666 20.633 25.1333C20.833 25.2166 21.0163 25.3333 21.183 25.4833C21.333 25.6499 21.4497 25.8333 21.533 26.0333C21.6163 26.2333 21.6663 26.4499 21.6663 26.6666C21.6663 26.8833 21.6163 27.0999 21.533 27.2999Z"
          fill="#D72229"
        />
      </svg>
    </>
  );
};

export default PopupErrorIcon;
