import { cn } from "@/utils/utils";

function EditIcon({ className }: { className?: string }) {
  return (
    <span className={cn("block w-6 h-6 text-gray-500 ", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"100%"}
        height={"100%"}
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M6.42971 19.283L6.42972 19.283L6.45149 19.2775L6.45151 19.2775L9.08384 18.6194C9.09896 18.6156 9.11396 18.6119 9.12885 18.6082C9.35159 18.5528 9.54877 18.5037 9.7278 18.4023C9.90683 18.301 10.0504 18.1571 10.2125 17.9947C10.2233 17.9838 10.2342 17.9729 10.2453 17.9618L17.4393 10.7678L17.4393 10.7678L17.4626 10.7445C17.7761 10.4311 18.0461 10.1611 18.2333 9.91573C18.4339 9.65281 18.5858 9.36038 18.5858 9C18.5858 8.63961 18.4339 8.34719 18.2333 8.08427C18.0461 7.83894 17.776 7.5689 17.4626 7.2555L17.4393 7.23223L17.0858 7.58579L17.4393 7.23223L17.2678 7.06066L17.2445 7.03738C16.9311 6.72395 16.6611 6.45388 16.4157 6.2667C16.1528 6.0661 15.8604 5.91421 15.5 5.91421C15.1396 5.91421 14.8472 6.0661 14.5843 6.2667C14.3389 6.45388 14.0689 6.72395 13.7555 7.03739L13.7322 7.06066L6.53816 14.2547C6.52714 14.2658 6.51619 14.2767 6.50533 14.2875C6.34286 14.4496 6.19903 14.5932 6.09766 14.7722C5.9963 14.9512 5.94723 15.1484 5.89179 15.3711C5.88809 15.386 5.88435 15.401 5.88057 15.4162L5.21704 18.0703C5.21483 18.0791 5.2126 18.088 5.21036 18.097C5.17112 18.2537 5.12921 18.421 5.11546 18.5615C5.10032 18.7163 5.10385 18.9773 5.31326 19.1867C5.52267 19.3961 5.78373 19.3997 5.93846 19.3845C6.07899 19.3708 6.24633 19.3289 6.40301 19.2896C6.41195 19.2874 6.42085 19.2852 6.42971 19.283Z"
          stroke="currentColor"
        />
        <path d="M13 7.5L16 5.5L19 8.5L17 11.5L13 7.5Z" fill="currentColor" />
      </svg>
    </span>
  );
}

export default EditIcon;
