import { cn } from "@/utils/utils";
import React from "react";

const BoxLightIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-12 h-12 text-gray-500", className)}>
      <svg
        width={"100%"}
        height={"100%"}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99805 17.6569C7.99805 16.8394 7.99805 16.4306 8.15029 16.0631C8.30253 15.6955 8.59156 15.4065 9.16962 14.8284L12.8265 11.1716C13.4045 10.5935 13.6936 10.3045 14.0611 10.1522C14.4286 10 14.8374 10 15.6549 10H32.3412C33.1587 10 33.5674 10 33.935 10.1522C34.3025 10.3045 34.5916 10.5935 35.1696 11.1716L38.8265 14.8284C39.4045 15.4065 39.6936 15.6955 39.8458 16.0631C39.998 16.4306 39.998 16.8394 39.998 17.6569V36C39.998 37.8856 39.998 38.8284 39.4123 39.4142C38.8265 40 37.8837 40 35.998 40H11.998C10.1124 40 9.16962 40 8.58383 39.4142C7.99805 38.8284 7.99805 37.8856 7.99805 36V17.6569Z"
          stroke="currentColor"
        />
        <path
          d="M7.99805 20H39.998"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M29.6644 20L18.3311 20C17.8597 20 17.6239 20 17.4775 20.1464C17.3311 20.2929 17.3311 20.5286 17.3311 21L17.3311 27.3333C17.3311 28.863 17.3311 29.6278 17.5289 30.0388C17.9705 30.9565 19.031 31.3958 19.9921 31.0592C20.4227 30.9084 20.9635 30.3676 22.0451 29.286C22.5461 28.785 22.7965 28.5345 23.0629 28.3937C23.6477 28.0844 24.3477 28.0844 24.9326 28.3937C25.1989 28.5345 25.4494 28.785 25.9503 29.286C27.0319 30.3676 27.5727 30.9084 28.0033 31.0592C28.9644 31.3958 30.025 30.9565 30.4666 30.0388C30.6644 29.6278 30.6644 28.863 30.6644 27.3333V21C30.6644 20.5286 30.6644 20.2929 30.5179 20.1464C30.3715 20 30.1358 20 29.6644 20Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default BoxLightIcon;
