import React from "react";
import { cn } from "@/utils/utils";

const StarFilledIcon = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-4 h-4 text-red-500", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M6.81542 4.50215C7.26377 3.34221 7.48794 2.76225 7.85214 2.68187C7.94791 2.66073 8.04688 2.66073 8.14265 2.68187C8.50685 2.76225 8.73102 3.34221 9.17937 4.50215C9.43433 5.16178 9.56181 5.49159 9.80035 5.71592C9.86726 5.77884 9.93989 5.83488 10.0172 5.88325C10.2929 6.0557 10.6371 6.08769 11.3254 6.15166C12.4907 6.25996 13.0733 6.31411 13.2512 6.65886C13.2881 6.73026 13.3131 6.80756 13.3254 6.88756C13.3843 7.27382 12.956 7.67821 12.0994 8.487L11.8615 8.7116C11.461 9.08973 11.2608 9.27879 11.145 9.51474C11.0755 9.65627 11.0289 9.8087 11.0071 9.96592C10.9707 10.228 11.0293 10.5023 11.1466 11.0508L11.1885 11.2468C11.3988 12.2306 11.504 12.7224 11.3727 12.9642C11.2548 13.1814 11.0376 13.3204 10.7977 13.3323C10.5306 13.3455 10.1542 13.0273 9.40147 12.3907C8.90555 11.9714 8.65759 11.7617 8.38233 11.6798C8.13077 11.6049 7.86402 11.6049 7.61246 11.6798C7.3372 11.7617 7.08924 11.9714 6.59332 12.3907C5.8406 13.0273 5.46424 13.3455 5.19713 13.3323C4.95721 13.3204 4.74001 13.1814 4.6221 12.9642C4.49083 12.7224 4.59599 12.2306 4.8063 11.2468L4.84821 11.0508C4.96548 10.5023 5.02412 10.228 4.98773 9.96592C4.9659 9.8087 4.91932 9.65627 4.84984 9.51474C4.73401 9.27879 4.53377 9.08973 4.13328 8.7116L3.8954 8.48701C3.03876 7.67821 2.61045 7.27382 2.66943 6.88756C2.68165 6.80756 2.7067 6.73026 2.74355 6.65886C2.92147 6.31411 3.50409 6.25996 4.66934 6.15166C5.35769 6.08769 5.70187 6.0557 5.97757 5.88325C6.0549 5.83488 6.12753 5.77884 6.19444 5.71592C6.43298 5.49159 6.56046 5.16178 6.81542 4.50215Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </span>
  );
};

export default StarFilledIcon;
