import React from "react";
import { cn } from "@/utils/utils";

const TruckIcon = ({ className }: { className?: string }) => {
	return (
		<span className={cn("block w-6 h-6 text-gray-500", className)}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 28 28" fill="none">
				<path d="M20.4141 7.09992H16.8738V5.10854C16.8738 4.86515 16.6747 4.66602 16.4313 4.66602H1.60659C1.3632 4.66602 1.16406 4.86515 1.16406 5.10854V19.9333C1.16406 20.1766 1.3632 20.3758 1.60659 20.3758H3.85242C4.06263 21.6923 5.20767 22.6991 6.58504 22.6991C7.96241 22.6991 9.10192 21.6923 9.31765 20.3758H16.4313H18.6771C18.8873 21.6923 20.0324 22.6991 21.4098 22.6991C22.7871 22.6991 23.9266 21.6923 24.1424 20.3758H26.3882C26.6316 20.3758 26.8307 20.1766 26.8307 19.9333V13.5166C26.8307 9.97636 23.9543 7.09992 20.4141 7.09992ZM25.9346 13.1294H21.6255C20.7072 13.1294 19.9549 12.3826 19.9549 11.4588V7.98498H20.4141C23.3348 7.98498 25.7299 10.2585 25.9346 13.1294ZM15.9888 5.55107V15.4527H2.04912V5.55107H15.9888ZM3.85242 19.4907H2.04912V16.3377H15.9888V19.4907H9.31765C9.10745 18.1742 7.96241 17.1675 6.58504 17.1675C5.20767 17.1675 4.06816 18.1742 3.85242 19.4907ZM6.58504 21.814C5.55063 21.814 4.70429 20.9677 4.70429 19.9333C4.70429 18.8988 5.55063 18.0525 6.58504 18.0525C7.61945 18.0525 8.46579 18.8988 8.46579 19.9333C8.46579 20.9677 7.61945 21.814 6.58504 21.814ZM21.4098 21.814C20.3753 21.814 19.529 20.9677 19.529 19.9333C19.529 18.8988 20.3753 18.0525 21.4098 18.0525C22.4442 18.0525 23.2905 18.8988 23.2905 19.9333C23.2905 20.9677 22.4442 21.814 21.4098 21.814ZM24.1424 19.4907C23.9322 18.1742 22.7871 17.1675 21.4098 17.1675C20.0324 17.1675 18.8929 18.1742 18.6771 19.4907H16.8738V7.98498H19.0699V11.4588C19.0699 12.8694 20.2149 14.0144 21.6255 14.0144H25.9457V19.4907H24.1424Z" fill="currentColor" />
			</svg>
		</span>
	);
};

export default TruckIcon;
