import { cn } from "@/utils/utils";
import React from "react";

const CardAbout = ({ className }: { className?: string }) => {
  return (
    <span className={cn("block w-[72px] h-[72px] text-gray-500", className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 73 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" width="72" height="72" rx="36" fill="white" />
        <path
          d="M52.0557 47.9823H21.6109C20.7861 47.9815 19.9953 47.6535 19.412 47.0703C18.8288 46.487 18.5008 45.6962 18.5 44.8714V27.1109C18.5008 26.2861 18.8288 25.4953 19.412 24.912C19.9953 24.3288 20.7861 24.0008 21.6109 24H52.0557C52.8806 24.0008 53.6714 24.3288 54.2547 24.912C54.8379 25.4953 55.1659 26.2861 55.1667 27.1109V44.8714C55.1659 45.6962 54.8379 46.487 54.2547 47.0703C53.6714 47.6535 52.8806 47.9815 52.0557 47.9823V47.9823ZM21.6109 25.1458C21.09 25.1466 20.5906 25.3539 20.2222 25.7222C19.8539 26.0906 19.6466 26.59 19.6458 27.1109V44.8714C19.6466 45.3923 19.8539 45.8917 20.2222 46.2601C20.5906 46.6284 21.09 46.8357 21.6109 46.8365H52.0557C52.5767 46.8357 53.0761 46.6284 53.4444 46.2601C53.8128 45.8917 54.0201 45.3923 54.0208 44.8714V27.1109C54.0201 26.59 53.8128 26.0906 53.4444 25.7222C53.0761 25.3539 52.5767 25.1466 52.0557 25.1458H21.6109Z"
          fill="currentColor"
        />
        <path
          d="M29.1534 37.6632H24.7161C24.4123 37.6632 24.1208 37.5424 23.9059 37.3275C23.691 37.1127 23.5703 36.8212 23.5703 36.5173V33.0111C23.5703 32.7072 23.691 32.4157 23.9059 32.2008C24.1208 31.986 24.4123 31.8652 24.7161 31.8652H29.1534C29.4573 31.8652 29.7487 31.986 29.9636 32.2008C30.1785 32.4157 30.2992 32.7072 30.2992 33.0111V36.5173C30.2992 36.8212 30.1785 37.1127 29.9636 37.3275C29.7487 37.5424 29.4573 37.6632 29.1534 37.6632ZM24.7161 33.0111V36.5173H29.1534V33.0111H24.7161Z"
          fill="currentColor"
        />
        <path
          d="M36.4974 42.9066H24.0651C23.9132 42.9066 23.7674 42.8462 23.66 42.7388C23.5525 42.6313 23.4922 42.4856 23.4922 42.3337C23.4922 42.1817 23.5525 42.036 23.66 41.9285C23.7674 41.8211 23.9132 41.7607 24.0651 41.7607H36.4974C36.6494 41.7607 36.7951 41.8211 36.9025 41.9285C37.01 42.036 37.0703 42.1817 37.0703 42.3337C37.0703 42.4856 37.01 42.6313 36.9025 42.7388C36.7951 42.8462 36.6494 42.9066 36.4974 42.9066Z"
          fill="currentColor"
        />
        <path
          d="M42.1513 37.1358C42.0461 37.1359 41.9429 37.1069 41.853 37.0522C41.7632 36.9975 41.6901 36.9191 41.6419 36.8256C41.5936 36.732 41.5721 36.6271 41.5796 36.5221C41.5871 36.4172 41.6233 36.3163 41.6843 36.2306C41.9837 35.8033 42.1443 35.2943 42.1443 34.7725C42.1443 34.2508 41.9837 33.7417 41.6843 33.3145C41.5966 33.1902 41.5618 33.0363 41.5876 32.8864C41.6133 32.7365 41.6976 32.603 41.8218 32.5152C41.9461 32.4275 42.1 32.3927 42.2499 32.4185C42.3998 32.4442 42.5333 32.5285 42.6211 32.6527C43.0565 33.2738 43.2902 34.014 43.2902 34.7725C43.2902 35.5311 43.0565 36.2712 42.6211 36.8923C42.5681 36.9677 42.4977 37.0292 42.416 37.0716C42.3342 37.114 42.2434 37.136 42.1513 37.1358V37.1358Z"
          fill="currentColor"
        />
        <path
          d="M44.2131 38.185C44.1115 38.1835 44.0123 38.1538 43.9267 38.0991C43.7976 38.0192 43.7055 37.8914 43.6706 37.7437C43.6357 37.596 43.6608 37.4405 43.7405 37.3113C44.2124 36.5458 44.4624 35.6641 44.4624 34.7647C44.4624 33.8654 44.2124 32.9837 43.7405 32.2181C43.6603 32.089 43.6347 31.9333 43.6694 31.7853C43.704 31.6373 43.7961 31.5091 43.9252 31.4289C43.9892 31.3892 44.0603 31.3625 44.1346 31.3503C44.2089 31.3382 44.2848 31.3407 44.3581 31.3579C44.5061 31.3925 44.6343 31.4845 44.7144 31.6137C45.2972 32.5614 45.6057 33.6522 45.6057 34.7647C45.6057 35.8773 45.2972 36.968 44.7144 37.9158C44.6616 38.0004 44.5875 38.0698 44.4996 38.117C44.4116 38.1643 44.3129 38.1877 44.2131 38.185Z"
          fill="currentColor"
        />
        <path
          d="M46.3585 39.2919C46.2583 39.2913 46.1599 39.2644 46.0733 39.2139C45.9867 39.1633 45.9148 39.091 45.865 39.004C45.8151 38.917 45.7889 38.8184 45.7891 38.7181C45.7892 38.6179 45.8157 38.5194 45.8658 38.4325C46.5167 37.3217 46.8597 36.0576 46.8597 34.7702C46.8597 33.4827 46.5167 32.2186 45.8658 31.1078C45.8035 30.9778 45.7924 30.829 45.8349 30.6912C45.8774 30.5534 45.9703 30.4367 46.0951 30.3644C46.2198 30.2921 46.3673 30.2694 46.508 30.301C46.6487 30.3326 46.7723 30.4161 46.8541 30.5349C47.6082 31.8215 48.0057 33.286 48.0057 34.7773C48.0057 36.2687 47.6082 37.7331 46.8541 39.0198C46.8021 39.1041 46.7291 39.1735 46.6422 39.2212C46.5554 39.2689 46.4576 39.2933 46.3585 39.2919Z"
          fill="currentColor"
        />
        <path
          d="M48.5758 40.4403C48.4752 40.4401 48.3764 40.4134 48.2893 40.3629C48.1578 40.2878 48.0614 40.1637 48.0211 40.0177C47.9808 39.8717 48 39.7156 48.0745 39.5837C48.9081 38.1147 49.3464 36.4546 49.3464 34.7655C49.3464 33.0765 48.9081 31.4163 48.0745 29.9473C47.9985 29.8151 47.9782 29.6581 48.0179 29.5109C48.0577 29.3637 48.1543 29.2384 48.2865 29.1624C48.4187 29.0864 48.5756 29.0661 48.7228 29.1058C48.87 29.1456 48.9954 29.2422 49.0714 29.3744C50.0035 31.0162 50.4935 32.8718 50.4935 34.7598C50.4935 36.6477 50.0035 38.5034 49.0714 40.1452C49.0222 40.2338 48.9505 40.3078 48.8634 40.3596C48.7764 40.4115 48.6771 40.4393 48.5758 40.4403Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default CardAbout;
